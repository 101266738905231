import React from "react"
import PrivateRoute from "../../../../../../components/auth/PrivateRoute/loadable"
import ProfileRoute from "../../../../../../components/auth/ProfileRoute"
import RequestExclusive from "../../../../../../components/RequestCustom/RequestExclusive"

const RequestExclusivePage = props => (
  <PrivateRoute url={props.location.href}>
    <ProfileRoute>
      <RequestExclusive url={props.location.href} />
    </ProfileRoute>
  </PrivateRoute>
)

export default RequestExclusivePage
